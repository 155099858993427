<template>
  <v-container
    class="mt-3"
    id="container"
    :class="$vuetify.breakpoint.lgAndUp ? '' : ''">
<!--    <div class="search-box">-->
<!--      <v-text-field v-model="search" outlined background-color="white" class="regular-font mt-7 rounded" rounded placeholder="جستجوی برند‌ها"/>-->
<!--    </div>-->
    <v-row dense class="d-flex justify-center align-center">
      <v-col v-for="(brand, index) in getList" :key="index" cols="6" sm="auto" v-show="brand.name.toUpperCase().indexOf(search.toUpperCase()) !== -1">
        <v-responsive min-height="130px" class="fill-height">
          <BrandItem :brand="brand" v-show="brand.name.toUpperCase().indexOf(search.toUpperCase()) !== -1"/>
        </v-responsive>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import BrandItem from "./BrandItem";

export default {
  name: "BrandList",

  components: {
    BrandItem,
  },

  data() {
    return {
      pageSize: 200,
      current: 1,
      end: false,
      search: ''
    };
  },

  methods: {
    ...mapActions("home", [
      "fetchBrands",
      "fetchProductsByBrandId",
      "setFilterType",
      "setFilter",
      "setBrandId",
      "abortRequests",
    ]),
    onBrandItemSelect(brand) {
      this.setFilterType("brand");
      this.setBrandId(brand.id);
      console.log(brand);
      this.setFilter({
        name: "",
        from: 0,
        count: 15,
        groupIds: [],
        brands: [+brand.id],
        variants: [],
        priceMin: 0,
        priceMax: 0,
      });
      // this.abortRequests();
      this.$router.push({ path: "/products", query: { brands: brand.id } });
      // this.$router.push({
      //   name: 'BrandsView',
      //   params: {
      //     id: brand.id,
      //     name: brand.name
      //   }
      // })
    },
  },

  computed: {
    ...mapGetters("home", [
      "getBrands",
      "getBrandsCount",
      "getSearchedBrands",
      "getShowSearchedBrands",
    ]),
    indexStart() {
      return (this.current - 1) * this.pageSize;
    },
    indexEnd() {
      return this.indexStart + this.pageSize;
    },
    getList() {
      return this.getShowSearchedBrands
        ? this.getSearchedBrands
        : this.getPaginatedBrands;
    },
    getPaginatedBrands() {
      return this.getBrands(0, this.indexEnd);
    },
  },

  beforeMount() {
    this.fetchBrands({ from: 0, count: 4 });
  },
};
</script>

<style scoped>
#container {
  padding-bottom: 65px !important;
}
.search-box{
  text-align: center;
}
.search-box .v-text-field {
  max-width: 500px;
  margin: auto;
}
</style>
