<template>
  <div>
    <AppBar />
    <div>
      <Home />
      <!-- <Cart v-show="getBottomNavigationSelectedItem == 3" /> -->
      <!-- <Bookmark v-show="getBottomNavigationSelectedItem == 2" /> -->
      <!-- <Profile v-show="getBottomNavigationSelectedItem == 1" /> -->
    </div>
    <!-- <v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition">
      <v-card color="#f0f0f0">
        <ProfileDetails v-if="getSelectedMenuItem == 1" />
        <Addresses v-if="getSelectedMenuItem == 2" />
        <Orders v-if="getSelectedMenuItem == 3" />
        <Rules v-if="getSelectedMenuItem == 4" />
        <ContactUs v-if="getSelectedMenuItem == 5" />
        <Wallet v-if="getSelectedMenuItem == 6" />
        <OrderCreated v-if="getSelectedMenuItem == 7" />
      </v-card>
    </v-dialog> -->
    <BottomNavigation />
  </div>
</template>

<script>
import AppBar from '../components/AppBar.vue'
import BottomNavigation from '../components/BottomNavigation.vue'
import Home from './HomeView.vue'
import { mapGetters } from 'vuex';

export default {
  name: "MainView",
  components: {
    AppBar,
    BottomNavigation,
    Home,
  },
  computed: {
    ...mapGetters(['getBottomNavigationSelectedItem']),
    getSelectedMenuItem() {
      return 0// this.$store.state.profile.selected_menu_item;
    },
    dialog: {
      get() {
        return this.$store.state.profile.show_dialog;
      },
      set(value) {
        this.$store.commit('profile/updateShowDialog', value)
      }
    }
  }
};
</script>

<style scoped></style>