<template>
  <div class="d-flex flex-column pt-3" :class="{ 'bg-white': isOpen }">
    <div
      @click="handleClick"
      class="section-title cursor-pointer pr-3 pl-3 d-flex jusitfy-between">
      <h4 class="regular-font text-999" :class="{ 'text-orange': isOpen }">
        {{ title }}
      </h4>

      <v-icon v-if="isOpen" color="#999999">mdi-chevron-up</v-icon>

      <v-icon v-else>mdi-chevron-left</v-icon>
    </div>

    <div>
      <div v-if="isOpen && type !== 'price'">
        <div class="pr-3 pl-3" v-for="(item, index) in items" :key="index">
          <v-checkbox
            class="regular-font"
            :label="getTitle(item)"
            color="red"
            :input-value="handleChecked(item)"
            @change="handleFilter($event, item)"
            hide-details></v-checkbox>
        </div>
      </div>

      <div class="pr-3 pl-3 mt-3 d-flex" v-else-if="isOpen && type === 'price'">
        <span class="regular-font mr-3 mt-2">از </span>
        <v-text-field
          dense
          class="regular-font mr-1"
          label=" از"
          placeholder="   از  "
          background-color="white"
          outlined
          v-model="minPrice"
          type="text"></v-text-field>

        <span class="regular-font mr-4 mt-2">تا </span>
        <v-text-field
          dense
          class="regular-font mr-1"
          label=" تا"
          placeholder="   تا  "
          background-color="white"
          outlined
          type="text"
          v-model="maxPrice"></v-text-field>
      </div>

      <div class="line-divider mt-2"></div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "FilterPane",
  emits: ["select"],
  props: {
    items: {
      // type: Object,
      default: null,
      require: true,
    },
    title: {
      type: String,
      default: "",
      require: true,
    },

    isOpen: {
      type: Boolean,
      default: false,
      require: true,
    },
    type: {
      type: String,
      default: null,
      require: true,
    },
  },
  data() {
    return {
      isActive: false,
      showOriginalName: true,
      showLirPrice: true,
      minPrice: 0,
      maxPrice: "",
    };
  },
  methods: {
    ...mapActions("price", ["convertLirToToman"]),
    ...mapActions("bookmark", ["addBookmark", "deleteBookmark"]),

    ...mapActions("home", ["setFilter"]),

    handleClick() {
      this.isOpen = !this.isOpen;
    },
    getTitle(item) {
      if (this.type === "group" || this.type === "brand") return item.name;
      else if (this.type === "variant") return item;
      else return "a";
    },
    handleFilter(event, item) {
      const groupIds = this.getFilter.groupIds;
      const brands = this.getFilter.brands;
      const variants = this.getFilter.variants;
      if (this.type === "brand") {
        const index = brands.findIndex((brand) => brand == item.id);
        index <= -1 && event ? brands.push(item.id) : brands.splice(index, 1);
      } else if (this.type === "variant") {
        const index = variants.findIndex((variant) => variant == item);
        index <= -1 && event ? variants.push(item) : variants.splice(index, 1);
      }
      console.log(brands)
      this.getFilter.groupIds = groupIds.filter(g => typeof g !== undefined && g !== null);
      this.getFilter.brands = brands.filter(g => typeof g !== undefined && g !== null);
      this.getFilter.variants = variants.filter(g => typeof g !== undefined && g !== null);

      this.setFilter(this.getFilter);
    },
    handleChecked(item) {
      const groupIds = this.getFilter.groupIds;
      const brands = this.getFilter.brands;
      const variants = this.getFilter.variants;

      if (this.type === "group") {
        const index = groupIds.findIndex((group) => group == item.id);

        return index <= -1 ? false : true;
      } else if (this.type === "brand") {
        const index = brands.findIndex((brand) => brand == item.id);

        return index <= -1 ? false : true;
      } else if (this.type === "variant") {
        const index = variants.findIndex((variant) => variant == item);

        return index <= -1 ? false : true;
      }
    },
  },
  computed: {
    ...mapGetters("price", ["getMultiplier"]),
    ...mapGetters("bookmark", ["isBookmarked"]),
    ...mapGetters("home", ["searchQuery", "getFilter"]),
  },
  watch: {
    minPrice(new_val, old_val) {
      var v = parseFloat(new_val)
      if (isNaN(v))
        this.getFilter.priceMin = 0
      else
        this.getFilter.priceMin = v

      this.setFilter(this.getFilter);
    },
    maxPrice(new_val, old_val) {
      var v = parseFloat(new_val)

      if (isNaN(v))
        this.getFilter.priceMax = 0
      else
        this.getFilter.priceMax = v

      this.setFilter(this.getFilter);
    },
  },
};
</script>

<style lang="scss" scoped>
.box-filter {
  background-color: #878787;
}
.filter-header-box {
  background-color: #ffe5c6;
  border-radius: 30px;
  padding: 10px 20px;
  flex-direction: column;
  align-items: center;
}
.flex-col {
  flex-direction: column;
}
.line-divider {
  background-color: #fd562e;
  height: 2px;
}
.text-orange {
  color: #fd562e !important;
}
.font-13 {
  font-size: 13px;
}
.font-15 {
  font-size: 15px;
}
.text-white {
  color: #ffffff;
}
.bg-white {
  background-color: #ffffff;
}
.bg-gray {
  background-color: #878787;
}
.jusitfy-between {
  justify-content: space-between;
}
.cursor-pointer {
  cursor: pointer;
}
.text-999 {
  color: #999;
}
</style>
