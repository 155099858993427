<template>
  <div class="d-flex flex-column pt-3" :class="{ 'bg-white': isOpen }">
    <div
      @click="handleClick"
      class="section-title cursor-pointer pr-3 pl-3 d-flex jusitfy-between">
      <h4 class="regular-font text-999" :class="{ 'text-orange': isOpen }">
        {{ title }}
      </h4>
      <v-icon v-if="isOpen" color="#999999">mdi-chevron-up</v-icon>
      <v-icon v-else>mdi-chevron-left</v-icon>
    </div>
    <div>
      <div v-if="isOpen">
        <div class="pr-3 pl-3" v-for="(item, index) in items" :key="index">
          <SubGroup :title="item.name" :subGroupIsOpen="false" :item="item" />
        </div>
      </div>
    </div>
    <div class="line-divider mt-2"></div>
  </div>
</template>

<script>
import SubGroup from "./SubGroup.vue";
export default {
  components: {
    SubGroup,
  },
  props: {
    items: {
      type: Array,
      default: null,
      require: true,
    },
    isOpen: {
      type: Boolean,
      default: false,
      require: true,
    },
    title: {
      type: String,
      default: null,
      require: true,
    },
  },
  methods: {
    handleClick() {
      this.isOpen = !this.isOpen;
    },
  },
};
</script>

<style>
.text-999 {
  color: #999;
}
.flex-col {
  flex-direction: column;
}
.line-divider {
  background-color: #fd562e;
  height: 2px;
}
.text-orange {
  color: #fd562e !important;
}
.font-13 {
  font-size: 13px;
}
.font-15 {
  font-size: 15px;
}
.text-white {
  color: #ffffff;
}
.bg-white {
  background-color: #ffffff;
}
.bg-gray {
  background-color: #878787;
}
.jusitfy-between {
  justify-content: space-between;
}
.cursor-pointer {
  cursor: pointer;
}
</style>
