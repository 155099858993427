<template>
  <div class="w-100 mt-2">
    <div
      @click="handleClick"
      class="section-title w-100 cursor-pointer pr-1 d-flex jusitfy-between">
      <h4
        class="regular-font text-999 mt-1"
        :class="{ 'text-orange': subGroupIsOpen }">
        {{ title }}
      </h4>
      <v-icon v-if="subGroupIsOpen" color="#999999">mdi-chevron-up</v-icon>
      <v-icon v-else>mdi-chevron-left</v-icon>
    </div>
    <div v-if="subGroupIsOpen">
      <div v-for="(items, index) in item.items" :key="index">
        <ThirdLevelSubGroup
          :title="items.name"
          :thirdLevelIsOpen="false"
          :items="items" />
      </div>
    </div>
  </div>
</template>

<script>
import ThirdLevelSubGroup from './ThirdLevelSubGroup.vue'
export default {
  props: {
    title: {
      type: String,
      default: null,
      require: true,
    },
    subGroupIsOpen: {
      type: Boolean,
      default: false,
      require: true,
    },
    item: {
      type: Array,
      default: null,
      require: true,
    },
  },
  components: {
    ThirdLevelSubGroup
  },
  methods: {
    handleClick() {
      console.log(this.subGroupIsOpen);
      this.subGroupIsOpen = !this.subGroupIsOpen;
    },
  },
  mounted() {
    // console.log(this.item);
  },
};
</script>
<style scoped>
.text-999 {
  color: #999;
}
.flex-col {
  flex-direction: column;
}
.text-orange {
  color: #fd562e !important;
}
.font-13 {
  font-size: 13px;
}
.font-15 {
  font-size: 15px;
}
.text-white {
  color: #ffffff;
}
.bg-white {
  background-color: #ffffff;
}
.bg-gray {
  background-color: #878787;
}
.jusitfy-between {
  justify-content: space-between;
}
.cursor-pointer {
  cursor: pointer;
}
</style>
