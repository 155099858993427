<template>
<v-btn elevation="0" @click.stop="changeDisplayMode" :outlined="getDisplayLang === 'fa' && outlined" :color="color" :block="block" class="d-flex flex-row justify-space-between rounded-xl" :class="getDisplayLang" style="width: auto">

    <p v-if="price !== -1" class="bold-font my-auto text-body-1">
        {{ getPrice }}
    </p>
    <div v-if="price !== -1" class="my-auto d-flex flex-row">
        <!-- <v-divider vertical class="mx-1"></v-divider> -->
        <p class="bold-font my-auto">{{ getDisplayLang === 'tr' ? "لیر" : "تومان" }}</p>
    </div>

  <v-progress-circular indeterminate color="primary" class="mx-auto" v-if="price === -1" />

    <!-- <div class="row">
        <div class="col-auto">
            {{ getPrice }}
        </div>
        <div class="col-auto">
            <v-divider vertical class="mx-0"></v-divider>
        </div>
        <div class="col-auto">
            {{ getDisplayLang == 'tr' ? "لیر" : "تومان" }}
        </div>
    </div> -->
</v-btn>
</template>

<script>
import {
    mapGetters
} from 'vuex';

export default {
    name: "PriceChip",
    props: {
        minWidth: {
            type: String,
            default: "auto",
        },
        maxWidth: {
            type: String,
            default: "auto",
        },
        outlined: {
            type: Boolean,
            default: true
        },
        block: {
            type: Boolean,
            default: false,
        },
        price: {
            type: Number,
            default: 0
        },
        color: {
            type: String,
            default: '#F9D1B4'
        },
        noToggle: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            showLirPrice: null,
            changeLanguage: null
        }
    },
    methods: {
        changeDisplayMode() {
            if (this.noToggle)
                return;
            if (this.changeLanguage == null)
                this.changeLanguage = true
            else
                this.changeLanguage = !this.changeLanguage
        }
    },
    computed: {
        ...mapGetters('price', ['getMultiplier']),
        ...mapGetters('home', ['getLang']),

        getDisplayLang() {
            if (this.changeLanguage === null || this.changeLanguage === false)
                return 'fa'//this.getLang;

            return 'tr'//this.getLang === 'fa' ? 'tr' : 'fa';
        },

        getPrice() {
            return this.getDisplayLang === 'fa' ? this.getTomanPrice : this.getLirPrice
        },

        getLirPrice() {
            return this.price.toFixed(2);
        },

        getTomanPrice() {
            if (this.price === 0) return this.price;
            if (this.getMultiplier) {
                return (Math.ceil(this.price * this.getMultiplier / 1000 ) * 1000).toLocaleString();
            }
            return this.getMultiplier;
        },
    }
};
</script>

<style lang="scss" scoped>
// .fb-btn.v-btn--outlined {
//     border: thin solid #ff4401;
// }

.v-btn {
    padding-left: 8px!important;
    padding-right: 8px!important;
}

.tr {
    background-color: #F9D1B4;
}

.tr p,
.tr .v-divider {
    color: black;
}

.fa p,
.fa .v-divider {
    color: #ff4401;
}
</style>
