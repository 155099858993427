<template>
  <v-lazy v-model="isActive" :options="{
      threshold: .3
    }" class="fill-height">
    <v-card class="mx-auto d-flex flex-column fill-height ribbon-container">

      <div>
        <v-img :src="this.product.images[0].thumbnail" class="mx-1" height="300"></v-img>
      </div>

      <div :dir="langDir" class="title-button">
            <span :class="getClass" class="content product-item__name">
                <!-- <span class="product-item__name" :class="(showOriginalName) ? 'regular-font' : 'opensans-regular'">{{ getTitle }} </span> -->
                {{ getTitle }}
                <span :class="getClass" class="product-item__brand">
                    {{ `(${product.brand.name})` }}
                </span>
            </span>
      </div>


      <v-card :to="{name: 'ProductView', params:{id: this.product.id, name: slug(this.product.name)}}"
              class="click-mask"
              target="_blank"></v-card>

      <div class="pa-2 bookmark-container">
        <v-btn :color="getBookmarkIconColor" icon outlined @click.stop="onBookmarkClick">
          <v-icon>mdi-heart</v-icon>
        </v-btn>
      </div>

      <div class="mt-auto mx-3 mb-3">
        <PriceChip :price="getPrice" block/>
      </div>

<!--      <ribbon-bar v-if="hasDiscount" color="green" text="تخفیف"></ribbon-bar>-->
      <ribbon-bar v-if="!exists" color="orange" text="تمام شد"></ribbon-bar>

    </v-card>
  </v-lazy>
</template>

<script>
import {
  mapActions,
  mapGetters
} from "vuex";
import PriceChip from "@/components/base/PriceChip.vue";
import {slug} from '@/utils.js'
import RibbonBar from "@/components/RibbonBar.vue";

export default {
  name: "ProductItem",
  emits: ['select'],
  props: {
    product: {
      type: Object,
      default: null,
      require: true,
    },
    price: {type: Number, default: -1},
    exists: {type: Boolean, default: false},
    bestVariant: {
      discountedPrice: 0,
      originalPrice: 0,
      sellingPrice: 0
    }
  },
  components: {
    RibbonBar,
    PriceChip,
  },
  data() {
    return {
      isActive: false,
      showOriginalName: true,
      showLirPrice: true,
    }
  },
  methods: {
    ...mapActions("price", ["convertLirToToman"]),
    ...mapActions('bookmark', ['addBookmark', 'deleteBookmark']),
    slug,
    onProductItemClick() {
      // this.$emit('select')
      this.$router.push({
        name: 'ProductView',
        params: {
          id: this.product.id,
          name: this.product.name
        }
      })
    },

    toggleTitleFont() {
      if (this.hasOriginalName) {
        this.showOriginalName = !this.showOriginalName
      }
      return false;
    },

    setShowOriginalName(value) {
      this.showOriginalName = value;
      return false
    },

    onBookmarkClick() {
      if (this.isBookmarked(this.product)) {
        this.deleteBookmark(this.product)
      } else {
        this.addBookmark(this.product)
      }
      return false;
    }
  },
  computed: {
    ...mapGetters("price", ["getMultiplier"]),
    ...mapGetters('bookmark', ['isBookmarked']),
    ...mapGetters('home', ['getLang', 'getClass']),

    getTitle() {
      if (this.getLang === 'fa')
        return this.product.translatedName ? this.product.translatedName : this.product.name;
      if (this.getLang === 'tr')
        return this.product.name

      return this.product.originalName
    },

    hasDiscount() {
      if (this.bestVariant == null)
        return false;

      return (this.bestVariant.discountedPrice !== 0 && this.bestVariant.discountedPrice < this.bestVariant.sellingPrice)
    },

    langIsFa() {
      return this.getLang === 'fa' && this.product.translatedName
    },
    langDir() {
      return this.langIsFa ? 'rtl' : 'ltr'
    },

    hasOriginalName() {
      return !!this.product.originalName
    },

    getBookmarkIconColor() {
      return (this.isBookmarked(this.product)) ? 'red' : 'grey'
    },
    getPrice() {
      if (!this.exists)
        return 0;

      if (this.bestVariant === undefined || this.bestVariant === null)
        return -1;

      return this.bestVariant.sellingPrice
    }
  },
};
</script>

<style lang="scss" scoped>

.title-button {
  color: #fff;
  border-radius: 5px;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  font-size: 1.04em;
  letter-spacing: 0.05em;
  width: auto;
  transition: 0.3s;
  padding: 16px;
  text-align: start;

  .content {
    position: relative;
  }
}

.click-mask {
  position: absolute;
  background: none;
  height: 100%;
  width: 100%
}

.bookmark-container {
  position: absolute;
}

.product-item {
  &__container {
    position: relative;
    cursor: pointer;
  }

  &-title {
    &__wrapper {
      text-align: left;
      -webkit-hyphens: auto;
      -moz-hyphens: auto;
      -ms-hyphens: auto;
      hyphens: auto;
      word-break: break-word;
      line-height: 25px;
    }
  }

  &__name {
    font-size: 14px;
    color: black;
    font-style: normal;
    font-weight: normal;
    hyphens: none;
    line-height: 1px;
  }

  &__brand {
    margin-bottom: 2rem;
    font-size: 12px;
  }

  &__price {
    direction: rtl;
    font-size: 18px;
    cursor: pointer;
  }

  &__tooltip {
    direction: rtl;
    font-size: 18px;
  }

  &__show {
    color: var(--primary-color);
    cursor: pointer;
  }
}
</style>
