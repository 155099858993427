import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import IranFlag from '@/components/icons/IranFlag'
import TurkiyeFlag from '@/components/icons/TurkiyeFlag'

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#ff4401',
        secondary: '#434750',
        accent: '#f0f0f0',
        white: '#ffffff'
      }
    },
  },
  icons: {
    values: {
      iran: {
        component: IranFlag
      },
      turkiye: {
        component: TurkiyeFlag
      }
    }
  },
  rtl: true
});
