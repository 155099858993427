<template>
  <div>
    <GroupList />

    <v-row dense class="d-flex mr-3 ml-3">
      <v-col v-if="$vuetify.breakpoint.width > 600" cols="2">
        <v-responsive class="p-sticky-0">
          <FiltersView :onFiltering="onFiltering" />
        </v-responsive>
      </v-col>

      <v-col :cols="$vuetify.breakpoint.width > 600 ? 8 : 12">
        <v-responsive>
          <div v-if="isLoadingProducts &&
            getProducts.length == 0 &&
            getProducts.length % 15 == 0
            " class="mt-15 d-flex flex-row">
            <v-progress-circular indeterminate color="primary" class="mx-auto"></v-progress-circular>
          </div>
          <ProductList />
        </v-responsive>
      </v-col>

      <v-col v-if="$vuetify.breakpoint.width > 600" cols="2">
        <v-responsive class="p-sticky-0">
          <AddProduct />
        </v-responsive>
      </v-col>
    </v-row>

    <v-dialog v-model="openFilter" overlay-opacity="0.9" v-if="$vuetify.breakpoint.width <= 600">
      <FiltersView :onFiltering="onFiltering" />
    </v-dialog>

    <v-dialog overlay-opacity="0.9" v-model="openAdd" v-if="$vuetify.breakpoint.width <= 600">
      <AddProduct />
    </v-dialog>

    <div class="d-flex ml-2 flex-column d-sm-none contact-us__icon d-block" v-if="$vuetify.breakpoint.width <= 600">
      <div class="d-flex flex-column justify-between align-center">
        <v-btn @click="showFilter('add')" class="mdi--filter2" fab size="small" color="primary" small>
          <v-icon class="black--text" color="white">mdi-plus</v-icon>
        </v-btn>

        <v-btn @click="showFilter('filter')" size="small" fab class="mdi--link2 mt-2" color="secondary" small>
          <v-icon class="black-text" color="white"> mdi-filter-outline </v-icon>
        </v-btn>
      </div>
    </div>
    <div v-if="isLoadingProducts" class="mt-3 mb-2 d-flex flex-row">
      <v-progress-circular indeterminate color="primary" class="mx-auto"></v-progress-circular>
    </div>
  </div>
</template>

<script>
import GroupList from "../components/home/groups/GroupList";
import ProductList from "../components/home/products/ProductList";
import AddProduct from "../components/features/AddProduct";
import { mapGetters, mapActions, mapMutations } from "vuex";
import FiltersView from "@/components/features/FiltersView.vue";

export default {
  name: "ProductsView",
  components: {
    GroupList,
    ProductList,
    AddProduct,
    FiltersView
},
  methods: {
    ...mapMutations("home", ["updateShowProducts", "updateShowProducts"]),
    ...mapActions("home", [
      "fetchProductsByBrandId",
      "fetchProductsByGroupId",
      "setFilterType",
      "setGroupId",
      "setSearchQuery",
      "setBrandId",
      "setSearchFilter",
      "setSearchInput",
      "setFilter",
      "clearSearchedProducts",
      "abortRequests",
    ]),
    onFiltering() {
      console.log("triggered");
      this.openFilter = !this.openFilter;
    },
    handleBackButton() {
      if (!this.$route.query.brand || this.$route.query.group) {
        if (this.showProducts) {
          this.updateShowProducts(false);
        }
      }
    },
    showFilter(type) {
      this.openAdd = false;
      this.openFilter = false;

      type === "filter" ? (this.openFilter = true) : (this.openAdd = true);
    },
    async onScroll() {
      if (
        !this.isLoadingProducts && this.lastProductsCount !== this.getProducts.length
        /*this.getProducts.length > 0 &&
        this.getProducts.length % 15 === 0*/
      ) {
        this.updateShowProducts(true);
        let bottomOfWindow =
          document.documentElement.scrollTop + window.innerHeight >=
          document.documentElement.offsetHeight * 0.9;
        if (this.$route.name === 'BrandsView')
          this.getFilter.brands = [this.$route.params.id]
        else
          this.getFilter.brands = this.getFilter.brands.filter(g => typeof g !== undefined && g !== null);
        this.getFilter.groupIds = (this.$route.query.groupIds instanceof String ? [this.$route.query.groupIds] : this.getFilter.groupIds).filter(g => typeof g !== undefined && g !== null);
        if (bottomOfWindow) {
          this.getFilter.from += this.getFilter.count;
          console.log(this.getFilter);
          this.setFilter(this.getFilter);
          let data = { from: this.getFilter.from };

          if (this.getFilterType === "filter") {
            console.log("its a filter");
            this.setSearchFilter();
          } else {
            this.initFilter.brands = this.$route.name === 'BrandsView'
              ? [this.$route.params.id]
              : [];
            this.setSearchFilter(this.initFilter);
            console.log("this isnt a filter", this.initFilter);
          }

          this.lastProductsCount = this.getProducts.length;
        }

      }
    },
  },
  computed: {
    ...mapGetters("home", [
      "isLoading",
      "isLoadingProducts",
      "showProducts",
      "brandsLoading",
      "searchQuery",
      "getFilter",
      "getProducts",
      "getFilterType",
      "getGroupId",
    ]),
  },
  data() {
    return {
      lastProductsCount: 0,
      openAdd: false,
      openFilter: false,
      pagination: 1,
      from: 0,
      count: 15,
      timeOutScroll: null,
      initFilter: {
        name: "",
        from: 0,
        count: 15,
        groupIds: [],
        brands: [],
        variants: [],
        priceMin: 0,
        priceMax: 0,
      },
    };
  },
  async mounted() {
    window.addEventListener("scroll", this.onScroll);
    window.addEventListener("popstate", this.handleBackButton);
    this.clearSearchedProducts();

    this.setFilter({
      name: "",
      from: 0,
      count: 15,
      groupIds: [],
      brands: [],
      variants: [],
      priceMin: 0,
      priceMax: 0,
    });

    let from = 0;
    let count = 15;
    let group = 0;
    let brand = 0;
    let variants = [];
    let name = "";
    let priceMin = 0;
    let priceMax = 0;

    if (this.$route.name === 'GroupView') {
      this.setFilterType("group");
      this.setGroupId(this.$route.params.id);
      this.initFilter.groupIds = [this.$route.params.id];
    }

    if (this.$route.name === 'Search') {
      this.setFilterType("keyword");
      this.setSearchQuery(this.$route.params.term);
      this.initFilter.name = this.$route.params.term;
    }
    name = this.$route.query.search;

    if (this.$route.name === 'BrandsView') {
      this.setFilterType("brand");
      console.log("setting the brand id -> ", this.$route.params.id);
      this.initFilter.brands = [this.$route.params.id];
      this.setBrandId(this.$route.params.id);
    } else if (this.$route.params.name === 'BrandsView') {
      this.initFilter.brands = [this.$route.params.id];
      this.setBrandId([this.$route.params.id]);
    }

    // if(this.$route.query.variants)
    // variants .push( this.$route.query.variants);

    // if(this.$route.query.priceMin)
    // priceMin  = this.$route.query.priceMin;

    // if(this.$route.query.priceMax)
    // priceMax  = this.$route.query.priceMax;

    const data = {
      from,
      count,
      group,
      name,
      brand,
      variants,
      priceMax,
      priceMin,
    };

    this.setSearchInput(data);
  },
  beforeDestroy() {
    // this.abortRequests();
    window.removeEventListener("scroll", this.onScroll);
    window.removeEventListener("popstate", this.handleBackButton);
  },
  watch: {
    async searchQuery(new_val, _old_val) {
      let data = { name: new_val };
      this.setSearchInput(data);

      this.initFilter = {
        name: new_val,
        from: 0,
        count: 15,
        groupIds: [],
        brands: [],
        variants: [],
        priceMin: 0,
        priceMax: 0,
      };
    },
    getGroupId(new_val, old_val) {
      console.log("ttt0", new_val);
      this.initFilter = {
        name: "",
        from: 0,
        count: 15,
        groupIds: [parseFloat(new_val)],
        brands: [],
        variants: [],
        priceMin: 0,
        priceMax: 0,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.contact-us {
  background-color: white;
  position: fixed;
  left: 0;
  bottom: 20px;
  padding: 0.5rem 1rem;
  gap: 0.5rem;
  border-radius: 5px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  text-decoration: none;
}

.contact-us:hover {
  cursor: pointer;
}

.contact-us p {
  color: var(--primary-color);
  text-decoration: none;
}

.contact-us__icon {
  position: fixed;
  top: 60px;
  justify-content: space-between;
  left: 5px;
  bottom: 60px;
  padding: 0;
  align-items: center;
}

.contact-us__icon:hover {
  cursor: pointer;
}

img {
  background-blend-mode: multiply;
  background-color: transparent;
  width: 30px;
  height: 30px;
}

.back-btn {
  position: absolute;
  left: 20px;
  top: 130px;
}

.mdi--link {
  background-color: blue;
  border-radius: 50%;

  margin-top: 5px;
  width: 35px;
  height: 35px;
}

.mdi--filter {
  background-color: #fd562e;
  border-radius: 50%;

  width: 35px;
  height: 35px;
  transform: rotate(180deg);
}

.z-ltr {
  direction: ltr !important;
}

.p-sticky-0 {
  position: sticky;
  top: 0px;
}

.br-50 {
  border-radius: 50%;
}
</style>
