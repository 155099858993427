<template>
  <v-container
      id="container"
      :class="$vuetify.breakpoint.lgAndUp ? '' : ''"
      class="mt-3"
  >
    <v-row v-if="getProducts.length > 0" dense>
      <template v-for="(product, index) in getProducts">
        <v-col :key="index"

            class="d-flex" cols="6" lg="2" md="4" sm="4" xl="2" xs="4">
          <v-responsive class="fill-height" color="transparent" min-height="450" style="overflow: unset">
            <ProductItem
                :exists="getPrices[product.id] === undefined ? true : getPrices[product.id].exists"
                :best-variant="getPrices[product.id] === undefined ? undefined : getPrices[product.id].bestVariant"
                :product="product"/>
          </v-responsive>
        </v-col>
      </template>
    </v-row>
  </v-container>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import ProductItem from "./ProductItem";

export default {
  name: "ProductList",
  components: {
    ProductItem,
  },
  data() {
    return {
      end: false,
    };
  },
  methods: {
    ...mapActions("price", ["fetchMultiplier"]),
    ...mapActions("product", ["setProduct"]),
    ...mapActions("home", ["fetchProductsByGroupId"]),
    onProductClick(product) {
      this.setProduct(product);
      this.$router.push({
        name: "Product",
        params: {
          product: product,
        },
        query: {
          id: product.id,
        },
      });
    },

    onScroll() {
      let bottomOfWindow =
          document.documentElement.scrollTop + window.innerHeight >=
          document.documentElement.offsetHeight * 0.9;
      if (bottomOfWindow) {
        // this.fetchProductsByGroupId(this.$route.query.group)
      }
    },
  },
  computed: {
    ...mapGetters("home", ["getProducts", "getProductsCount", "getPrices"]),
  },
  beforeMount() {
    this.fetchMultiplier();
  },
};
</script>

<style lang="scss" scoped>
#container {
  @media (max-width: 768px) {
    padding-bottom: 65px !important;
  }
}
</style>
