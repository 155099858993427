<template>
  <div class="w-100 mt-2 pr-4">
    <div
      @click="handleClick"
      class="section-title w-100 cursor-pointer d-flex jusitfy-between">
      <h4
        class="regular-font text-999 mt-1"
        :class="{ 'text-orange': thirdLevelIsOpen }">
        - {{ title }}
      </h4>
      <v-icon v-if="thirdLevelIsOpen" color="#999999">mdi-chevron-up</v-icon>
      <v-icon v-else>mdi-chevron-left</v-icon>
    </div>
    <div v-if="thirdLevelIsOpen">
      <div v-for="(item, index) in items.items" :key="index">
        <v-checkbox
          class="regular-font"
          :label="item.name"
          :input-value="handleChecked(item)"
          @change="handleFilter($event, item)"
          color="red"
          hide-details></v-checkbox>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  components: {},
  props: {
    title: {
      type: String,
      default: null,
      require: true,
    },
    thirdLevelIsOpen: {
      type: Boolean,
      default: false,
      require: true,
    },
    items: {
      type: Array,
      default: null,
      require: true,
    },
  },
  computed: {
    ...mapGetters("home", ["getFilter"]),
  },
  methods: {
    handleClick() {
      console.log(this.thirdLevelIsOpen);
      this.thirdLevelIsOpen = !this.thirdLevelIsOpen;
    },
    handleFilter(event, item) {
      const groupIds = this.getFilter.groupIds;
      const index = groupIds.findIndex((group) => group == item.id);

      index <= -1 && event ? groupIds.push(item.id) : groupIds.splice(index, 1);
      this.getFilter.groupIds = groupIds.filter(g => typeof g !== undefined && g !== null);
      this.setFilter(this.getFilter);
    },
    handleChecked(item) {
      const groupIds = this.getFilter.groupIds;
      const index = groupIds.findIndex((group) => group == item.id);
      return index <= -1 ? false : true;
    },
  },
};
</script>
<style scoped>
.text-999 {
  color: #c09494;
}
.flex-col {
  flex-direction: column;
}
.text-orange {
  color: #fd562e !important;
}
.font-13 {
  font-size: 13px;
}
.font-15 {
  font-size: 15px;
}
.text-white {
  color: #ffffff;
}
.bg-white {
  background-color: #ffffff;
}
.bg-gray {
  background-color: #878787;
}
.jusitfy-between {
  justify-content: space-between;
}
.cursor-pointer {
  cursor: pointer;
}
</style>
