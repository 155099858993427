<template>
<div>
    <v-dialog v-model="openAdd" v-if="$vuetify.breakpoint.width <= 600" overlay-opacity="0.9" >
        <AddProduct @finish="openAdd = false" class="mobile-add-product"/>
    </v-dialog>

    <GroupList />
    <div v-if="isLoading || brandsLoading" class="mt-3 d-flex flex-row">
        <v-progress-circular indeterminate color="primary" class="mx-auto"></v-progress-circular>
    </div>



    <v-row dense class="d-flex mr-3 ml-3">
        <!-- <v-col v-if="$vuetify.breakpoint.width > 600" cols="2">
            <v-responsive class="p-sticky-0">
                <AddAdvertise />
            </v-responsive>
        </v-col> -->
        <v-col :cols="$vuetify.breakpoint.width > 600 ? 10 : 12">
<!--            <v-container>-->
<!--              <v-alert closable-->

<!--                       border="start"-->
<!--                       type="info"-->
<!--                       icon="mdi-information"-->
<!--                       title="پرداخت هزینه ارسال">-->
<!--                فاکتور پرداخت نشده‌ای برای بارهای شما صادر شده است. می‌توانید انها-->
<!--                <br />-->
<!--                <v-btn link class="mt-2" ></v-btn>-->
<!--              </v-alert>-->
<!--            </v-container>-->
            <v-responsive>
              <BrandList />
            </v-responsive>
        </v-col>
        <v-col v-if="$vuetify.breakpoint.width > 600" cols="2">
            <v-responsive class="p-sticky-0">
                <AddProduct />
            </v-responsive>
        </v-col>
    </v-row>

    <BottomNavigation />

    <div class="d-flex ml-5 flex-column d-sm-none contact-us__icon d-block" v-if="$vuetify.breakpoint.width <= 600">
        <v-btn @click="showFilter" color="primary" class="mdi--link2" size="small" small fab>
            <v-icon class="black--text" color="white">mdi-plus</v-icon>
        </v-btn>
    </div>
</div>
</template>

<script>
import GroupList from "../components/home/groups/GroupList";
import BrandList from "../components/home/brands/BrandList";
import AddProduct from "../components/features/AddProduct";
import AddAdvertise from "../components/features/AddAdvertise";
import BottomNavigation from "@/components/BottomNavigation.vue";

import {
    mapGetters,
    mapActions,
    mapMutations
} from "vuex";

export default {
    name: "HomeView",
    components: {
        GroupList,
        BrandList,
        AddProduct,
        AddAdvertise,
        BottomNavigation,
    },
    data() {
        return {
            openAdd: false,
        };
    },

    methods: {
        ...mapMutations("home", ["updateShowProducts"]),
        ...mapActions("home", ["fetchProductsByBrandId", "fetchProductsByGroupId"]),
        handleBackButton() {
            if (!this.$route.query.brand || this.$route.query.group) {
                if (this.showProducts) {
                    this.updateShowProducts(false);
                }
            }
        },
        showFilter() {
            this.openAdd = true;
        },
        setSelectedBottomNavigationItem(value) {
            this.$store.dispatch("setBottomNavigationSelectedItem", value);
            if (value == 4) {
                this.$router.replace({
                    name: "Home",
                    query: {},
                });
                this.$store.commit("home/updateShowProducts", false);
                this.$store.commit("home/updateShowSearchedBrands", false);
            }
        },
    },
    computed: {
        ...mapGetters("home", ["isLoading", "showProducts", "brandsLoading"]),
    },
    mounted() {
        window.addEventListener("popstate", this.handleBackButton);
        // if (this.$route.query.brand) {
        //   this.fetchProductsByBrandId(this.$route.query.brand);
        // }
        // if (this.$route.query.cart) {
        //   this.setSelectedBottomNavigationItem(3);
        // } else if (this.$route.query.favorite) {
        //   this.setSelectedBottomNavigationItem(2);
        // } else if (
        //   this.$route.query.profile &&
        //   this.$vuetify.breakpoint.width <= 600
        // ) {
        //   this.setSelectedBottomNavigationItem(1);
        // } else {
        //   this.setSelectedBottomNavigationItem(4);
        // }

        // if (this.$route.query.myaddress) {
        //   this.$store.commit("profile/updateSelectedMenuItem", 2);
        // } else if (this.$route.query.myorders) {
        //   this.$store.commit("profile/updateSelectedMenuItem", 3);
        // } else if (this.$route.query.rules) {
        //   this.$store.commit("profile/updateSelectedMenuItem", 4);
        // } else if (this.$route.query.contact) {
        //   this.$store.commit("profile/updateSelectedMenuItem", 5);
        // }
    },
    beforeDestroy() {
        window.removeEventListener("popstate", this.handleBackButton);
    },
};
</script>

<style scoped>
.contact-us {
    background-color: white;
    position: fixed;
    left: 0;
    bottom: 20px;
    padding: 0.5rem 1rem;
    gap: 0.5rem;
    border-radius: 5px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    text-decoration: none;
}

.contact-us:hover {
    cursor: pointer;
}

.contact-us p {
    color: var(--primary-color);
    text-decoration: none;
}

.contact-us__icon {
    position: fixed;
    top: 120px;
    justify-content: space-between;
    align-items: center;
    left: -5px;
    bottom: 80px;
    padding: 0;
    align-items: center;
}

.icons {
    justify-content: space-between;
    align-items: center;
}

.contact-us__icon:hover {
    cursor: pointer;
}

img {
    background-blend-mode: multiply;
    background-color: transparent;
    width: 30px;
    height: 30px;
}

.back-btn {
    position: absolute;
    left: 20px;
    top: 130px;
}

.mdi--link {
    background-color: rgb(255, 123, 0);
    border-radius: 50%;
    padding: 5px;
    margin-top: 5px;
    width: 35px;
    height: 35px;
}

.p-sticky-0 {
    position: sticky;
    top: 0px;
}

.br-50 {
    border-radius: 50%;
}

.mobile-add-product {
    width: calc(100% - 50px);
}
</style>
