import Vue from 'vue'
import VueRouter,{createWebHashHistory} from 'vue-router'
import store from '@/store'
import Main from '../views/Main'
import ProductsList from '../views/ProductsList'
import CartConfirm from '../components/cart/CartConfirm'
import Register from '../views/Register'
import Login from '../views/Login';

Vue.use(VueRouter)

const main_layout_routes = [
  
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/HomeView.vue'),
    meta: {
      showSocialMedias: true
    }
  },
  // profile
  {
    path: '/profile/favourites',
    name: 'ProfileFavourites',
    component: () => import('../views/Bookmark.vue'),
    meta: {
      showSocialMedias: false
    }
  },
  {
    path: '/profile/view',
    name: 'ProfileView',
    component: () => import('../views/ProfilePane.vue'),
    meta: {
      showSocialMedias: false
    }
  },
  {
    path: '/profile/addresses',
    name: 'ProfileAddresses',
    component: () => import('../pages/AddressesPage.vue'),
    meta: {
      showSocialMedias: true
    }
  },
  {
    path: '/profile/orders',
    name: 'ProfileOrders',
    component: () => import('../pages/OrdersPage.vue'),
    meta: {
      showSocialMedias: true
    }
  },
  {
    path: '/profile/rules',
    name: 'ProfileRules',
    component: () => import('../pages/RulesPage.vue'),
    meta: {
      showSocialMedias: true
    }
  },
  {
    path: '/profile/contactus',
    name: 'ProfileContactUs',
    component: () => import('../pages/ContactUsPage.vue'),
    meta: {
      showSocialMedias: true
    }
  },
  {
    path: '/profile/details',
    name: 'ProfileDetails',
    component: () => import('../components/profile/ProfileDetails.vue'),
    meta: {
      showSocialMedias: false
    }
  },
  //store
  {
    path: '/product/:id/:name',
    name: 'ProductView',
    component: () => import('../views/ProductView.vue'),
    meta: {
      showSocialMedias: false
    }
  },
  {
    path: '/brands/:id/:name',
    name: 'BrandsView',
    component: () => import('../views/ProductsList.vue'),
    meta: {
      showSocialMedias: true
    }
  },
  {
    path: '/group/:id/:name',
    name: 'GroupView',
    component: () => import('../views/ProductsList.vue'),
    meta: {
      showSocialMedias: true
    }
  },
  {
    path: '/search/:term',
    name: 'Search',
    component: () => import('../views/ProductsList.vue'),
    meta: {
      showSocialMedias: true
    }
  },
  //
  
  {
    path: '/basket/confirm',
    name: 'ConfirmBasket',
    component: () => import('../pages/CartPage.vue'),
    meta: {
      showSocialMedias: false
    }
  },
]

const routes = [
  {
    path: '',
    // name: 'default',
    component: () => import('../layouts/DefaultLayout.vue'),
    children: main_layout_routes
  },
  
  {
    path: '/users/register',
    name: 'Register',
    component: Register
  },
  {
    path: '/users/login',
    name: 'Login',
    component: () => import('../views/Login.vue')
  },
]

import goTo from 'vuetify/lib/services/goto'


const router = new VueRouter({
  mode: 'history',
  scrollBehavior: (to, from, savedPosition) => {
    let scrollTo = 0

    if (to.hash) {
      scrollTo = to.hash
    } else if (savedPosition) {
      scrollTo = savedPosition.y
    }

    return goTo(scrollTo)
  },
  routes
})


// router.beforeEach((to, from, next) => {
//   const authenticated = store.getters['auth/authenticated']

//   if (to.matched.some((record) => record.meta.requiresAuth)) {
//     if (!authenticated) {
//       router.push({
//         name: "Login",
//       });
//     } else {
//       next();
//     }
//   } else {
//     next();
//   }
// });

export default router
