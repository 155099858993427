<template>
  <v-card class="mx-auto d-flex flex-column box-filter mt-5">
    <div class="filter_wrapper">
      <h3 class="medium-font font-15 justify-center d-flex mt-3">
        <span :data-tooltip="content"> فیلتر </span>
      </h3>

      <div class="line-divider mt-4"></div>
      <div class="filter-sections">
        <FilterSubGroup
          v-if="!$route.query.groupIds"
          :items="getInitialFilter.types"
          title=" گروه"
          :isOpen="false" />
        <FilterPane
          v-if="!$route.query.brands"
          :items="getInitialFilter.brands"
          title="برند"
          :isOpen="false"
          type="brand" />
        <FilterPane
          :items="getInitialFilter.variants"
          title="سایز"
          :isOpen="false"
          type="variant" />
        <FilterPane
          :items="[1, 2, 3]"
          title=" قیمت (لیر)"
          :isOpen="false"
          type="price" />
      </div>
    </div>

    <div class="d-flex justify-center button-container" @click="handleFilters">
      <v-btn
        class="regular-font mt-2 mr-5 ml-5 btn-filter mb-4 white--text"
        height="50"
        variant="text"
        color="#FD562E">
        اعمال
      </v-btn>
    </div>
  </v-card>
</template>

<script>import { mapActions, mapGetters } from "vuex";
import Vue from "vue";
import VTooltip from "v-tooltip";
var tooltip = require("tooltip");
import FilterSubGroup from "./FilterSubGroup";
import FilterPane from "./FilterPane.vue";

var config = {
  showDelay: 100,
  style: {
    padding: 5,
    color: "#FD562E",
    left: "0px",
    fontFamily: "Regular",
    backgroundColor: "#FFE5C6",
    position: "absolute",
    with: "200",
    height: "auto",
    zIndex: 1000,
  },
};
tooltip(config);
Vue.use(VTooltip);

export default {
  name: "FiltersView",
  emits: ["select"],
  props: {
    onFiltering: {
      type: Function,
    },
    product: {
      type: Object,
      default: null,
      require: true,
    },
  },
  components: {
    FilterSubGroup,
    FilterPane
},
  data() {
    return {
      isActive: false,
      showOriginalName: true,
      showLirPrice: true,
      variants: [],
      content:
        " برای دسترسی دقیق تر به  محصولات " + "نتایج را از فرم زیر فیلتر کنید ",
    };
  },
  methods: {
    ...mapActions("price", ["convertLirToToman"]),
    ...mapActions("home", [
      "fetchBrands",
      "fetchProductsByGroupId",
      "setSearchFilter",
      "setFilter",
      "setFilterType",
    ]),
    ...mapActions("bookmark", ["addBookmark", "deleteBookmark"]),

    handleFilters() {
      this.getFilter.from = 0;

      this.onFiltering();
      
      this.setFilterType("filter");
      
      this.setFilter(this.getFilter);
      
      this.setSearchFilter();
    },
  },
  mounted() {
    // console.log("initial filter",this.getInitialFilter);
  },
  computed: {
    ...mapGetters("price", ["getMultiplier"]),
    ...mapGetters("bookmark", ["isBookmarked"]),
    ...mapGetters("home", [
      "getGroups",
      "getBrands",
      "getProducts",
      "getInitialFilter",
      "getBrandsCount",
      "getSearchedBrands",
      "getShowSearchedBrands",
      "getFilter",
    ]),
    // getVariants() {
    //   //const variants = this.updatedVariants.length? this.updatedVariants:this.getProduct ? this.getProduct.variants : [];
    //   // return variants.map((variant) => {
    //   //   return {
    //   //     ...variant,
    //   //     disabled: !variant.exists,
    //   //   };
    //   // });
    // },
    getBrandList() {
      return this.getShowSearchedBrands
        ? this.getSearchedBrands
        : this.getPaginatedBrands;
    },
    getPaginatedBrands() {
      return this.getBrands(0, this.indexEnd);
    },
  },
  watch: {
    getProducts(new_val, old_val) {
      let sizes = [];
      new_val.map((item) => {
        sizes.push(...item.variants);
      });

      const seen = new Set();
      const uniqueAuthors = sizes.filter((item) => {
        const duplicate = seen.has(item.id);
        seen.add(item.id);
        return !duplicate;
      });

      this.variants = uniqueAuthors;
    },
  },
};
</script>

<style lang="scss" scoped>
.box-filter {
  width: 100%;
  background-color: #fff;
  position: relative;
  display: flex;
  max-height: calc(100vh - 130px);
  min-height: calc(100vh - 130px);
  justify-content: space-between;
  overflow-y: scroll;
}
.filter-sections {
  /* height: 65vh; */
  overflow-y: auto;
}
.filter-header-box {
  background-color: #ffe5c6;
  border-radius: 30px;
  padding: 10px 20px;
  flex-direction: column;
}
.button-container {
  position: sticky;
  width: 100%;
  background: white;
  left: 0;
  right: -10px;
  bottom: 0;
}
.flex-col {
  flex-direction: column;
}
.line-divider {
  background-color: #fd562e;
  height: 2px;
}
.text-orange {
  color: #fd562e;
}
.font-13 {
  font-size: 13px;
}
.font-15 {
  font-size: 15px;
}
.btn-filter {
  width: 80%;
}
</style>
